import React from 'react';

const SamplePrompts = ({ handleSamplePromptClick }) => {
    
    return (
        <div className="sprompt-container">
            <div className='sprompt-info'>Or click the following box to ask a sample question</div>
            <div className='sprompt-title'><strong>on Predictive Analytics Partners</strong></div>
            <div className='sprompt-parent'>
                
                <div className='sprompt-child'>
                    <button className="sample-prompts-button" onClick={() => handleSamplePromptClick("what does predictive analytics partners do?")}>
                        What does this company do?
                    </button>
                </div>
                <div className='sprompt-child'>
                    <button className="sample-prompts-button" onClick={() => handleSamplePromptClick("what do you do pati b?")}>
                        What do you do PATI B?
                    </button>
                </div>
                <div className='sprompt-child'>
                    <button className="sample-prompts-button" onClick={() => handleSamplePromptClick("what is predictive analytics?")}>
                        What is Predictive Analytics?
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SamplePrompts;
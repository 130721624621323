import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router'
import { createBrowserHistory } from 'history'
import {applyMiddleware, compose, combineReducers, createStore} from 'redux';
import { routerReducer, routerMiddleware } from 'react-router-redux'
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import API_Root from "./Constants/API_Root";
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly'
import mainState from './Reducers/MainReducers'
import 'react-toastify/dist/ReactToastify.css';

const history = createBrowserHistory();

const allReducers = combineReducers({
    mainState,
    router: routerReducer
});

const middleware = [
    thunk,
    routerMiddleware(history)
];

let allStoreEnhancers;

if (API_Root === "http://127.0.0.1:80/"){
    allStoreEnhancers = composeWithDevTools(
        applyMiddleware(...middleware),
    );
}
else{
    allStoreEnhancers = compose(
        applyMiddleware(...middleware),
    );
}

const store = createStore(
    allReducers,
    allStoreEnhancers
);

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <App />
        </Router>
    </Provider>,
    document.getElementById('root'));

export { store }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

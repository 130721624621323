import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleToast} from "../Actions/ToggleToast";
import { auth } from '../Authentication/firebase';
import { Redirect } from 'react-router-dom'; 


class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            loggedIn: false,
        };
    }
    checkIfLoggedIn() {
        //checks if email is in localStorage already
        const storedEmail = localStorage.getItem('email');
        if (storedEmail) {
            this.setState({ email: storedEmail });
            this.handleLogin();
        }
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleLogin = () => {
        const { email, password } = this.state;
        console.log("login initiated");
        auth.doSignInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                localStorage.setItem('email', email);
                this.setState({ loggedIn: true });
                this.props.toggleToast({ show: true, type: "success", message: "Logged in!" });
                window.location.reload();
            })
            .catch((error) => {
                console.error('Login Error:', error);
                this.props.toggleToast({ show: true, type: "error", message: error });
            });
    }

    render() {
        return (
            <div style={{ padding: "0 600px" }}>
                <div className={"signupBG"}>
                    <div className="signup-entry">
                        <div className={"signupTitle"}>
                            <strong>Login</strong>
                        </div>
                        <div className={"signupBox"}>
                            <label>Email:</label>
                            <input 
                            type="email"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                            />

                            <label>Password:</label>
                            <input 
                            type="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.handleInputChange}
                            />
                        
                            <button className={"signupButton"} onClick={this.handleLogin}>Login</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {toggleToast: toggleToast}

export default connect(mapStateToProps, mapActionsToProps)(Login)
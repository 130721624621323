export function generateUniqueThreadId() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const threadIdLength = 16;
    let threadId = '';
  
    for (let i = 0; i < threadIdLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      threadId += characters.charAt(randomIndex);
    }
  
    return "thread-" + threadId;
  }
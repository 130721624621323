import React, { Component } from 'react';
import Routes from './Routes';
import { connect } from 'react-redux';
import CustomToastContainer from "./SharedComponents/CustomToastContainer"
import LoadingModal from "./SharedComponents/LoadingModal"

class App extends Component {
    render(){
        return(
            <div>
                <LoadingModal name="loadingModalUploadedPictures" header="Uploading your picture..." message="Uploading your picture..." noClose/>
                <LoadingModal name="loadingModalSavingCSVUpload" header="Saving file details..." message="Saving file details......" noClose/>
                <div className="main">
                    <div>
                        <CustomToastContainer />
                        <Routes />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(App);

import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Root from '../Constants/API_Root';
import {toggleToast} from "../Actions/ToggleToast";
import { auth } from '../Authentication/firebase';


class Signup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
        };
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    
    handleSignup = () => {
        const { email, password } = this.state;
        console.log("signing up initiated")
        auth.doCreateUserWithEmailAndPassword(email, password)
        .then(() => {
            localStorage.setItem('email', email);
            this.props.toggleToast({show: true, type: "success", message:"Account Created!"});
            fetch(API_Root + "postgres-db", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json' // Set content type to JSON
                },
                body: JSON.stringify({
                    "username": this.state.email
                })
                
            })
        })
        .catch((error) => {
            console.error('Signup Error:', error);

            this.props.toggleToast({show: true, type: "error", message: error}); 
        })
    }

    render() {
        return (
            <div style={{ padding: "0 600px" }}>
                <div className={"signupBG"}>
                    <div className="signup-entry">
                        <div className={"signupTitle"}>
                            <strong>Sign Up</strong>
                        </div>
                        <div className={"signupBox"}>
                            <label>Email:</label>
                            <input 
                            type="email"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                            />

                            <label>Password:</label>
                            <input 
                            type="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.handleInputChange}
                            />
                        
                            <button className={"signupButton"} onClick={this.handleSignup}>Sign Up</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapActionsToProps = { toggleToast: toggleToast };

export default connect(mapStateToProps, mapActionsToProps)(Signup);
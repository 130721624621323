import React from 'react';
import { marked } from 'marked';

const QuestionAnswer = ({ chatHistory }) => {

  // Convert Markdown to HTML
  const renderMarkdown = (markdownText) => {
    return { __html: marked.parse(markdownText) };
  };

  const handleScheduleCallClick = () => {
    window.open('https://calendly.com/predictive-analytics-partners', '_blank');
  };

  // Function to render chat entries including the default message
  const renderChatEntries = () => {
    const entries = [];
    for (let i = 0; i < chatHistory.length; i++) {
      entries.push(
        <div key={i} className="chat-entry">
          <div className="prompt">
            <div className='chat-flex'>
              <div className='profile-box'>
                <div className='profile-image'>
                  <img src="https://predanalytics-assets.s3.amazonaws.com/promptava.png" alt="Profile Image" />
                </div>
              </div>
              <div className='chat-box' dangerouslySetInnerHTML={renderMarkdown(chatHistory[i].prompt)}></div>
            </div>
          </div>
          <div className="answer">
            <div className='chat-flex'>
              <div className='profile-box'>
                <div className='profile-image'>
                  <img src="https://predanalytics-assets.s3.amazonaws.com/answerava.jpg" alt="Profile Image" />
                </div>
              </div>
              <div className='chat-box' dangerouslySetInnerHTML={renderMarkdown(chatHistory[i].answer)}></div>
            </div>
          </div>
        </div>
      );

      // After every third entry, add a default message
      if ((i + 1) % 3 === 0) {
        entries.push(
          <div key={`default-${i}`} className="chat-entry default-message">
            <div className="answer">
              <div className='chat-contactus'>
                <div><strong><em>If you would like to learn more, schedule a call with us to gain professional, in-depth strategies and insights.</em></strong></div>
                <button className='chat-contactbutton' onClick={handleScheduleCallClick}><strong>Click here to schedule a call</strong></button>
              </div>
            </div>
          </div>
        );
      }
    }
    return entries;
  };

  return (
    <div>
      {renderChatEntries()}
    </div>
  );
};

export default QuestionAnswer;

import firebase from 'firebase/app';
import 'firebase/auth';

// Initialize Firebase
const config = {
    apiKey: "AIzaSyCqrSoUelhEQ-g4T0kk8PTRulq9uXhbtWQ",
    authDomain: "chatbot-test-pred-analytics.firebaseapp.com",
    databaseURL: "https://spreadsheet-api-f43dc.firebaseio.com",
    projectId: "chatbot-test-pred-analytics",
    storageBucket: "chatbot-test-pred-analytics.appspot.com",
    messagingSenderId: "862052524868",
    appId: "1:862052524868:web:2dd3cf2f249a36269ced11"
};

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

const auth = firebase.auth();

export {
    auth,
};
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleToast} from "../Actions/ToggleToast";
import { auth } from '../Authentication/firebase';
import { Redirect } from 'react-router-dom'; 


class Logout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            
        };
    }

    handleLogout = () => {
        auth.doSignOut()
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                console.error('Logout Error:', error);
                this.props.toggleToast({ show: true, type: 'error', message: error });
            });
    }

    render() {
        return (
            <div style={{ padding: "0 600px" }}>
        <div className={"signupBG"}>
          <div className="signup-entry">
            <div className={"signupTitle"}>
              <strong>Logout</strong>
            </div>
            <div className={"signupBox"}>
              <button className={"signupButton"} onClick={this.handleLogout}>Logout</button>
            </div>
          </div>
        </div>
      </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {toggleToast: toggleToast}

export default connect(mapStateToProps, mapActionsToProps)(Logout)
// API for testing or Development

const browserDomain = window.location.origin;

let API_Root;

if (browserDomain.includes("localhost")){
    API_Root = "http://127.0.0.1:80/";
}
else{
    API_Root = "https://dpnbqpleg3e5w.cloudfront.net/";
}

export default API_Root
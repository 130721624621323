import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Root from '../Constants/API_Root';
import QuestionAnswer from '../QuestionAnswer';
import {toggleToast} from "../Actions/ToggleToast";
import { generateUniqueSessionId } from '../UniqueId';
import { generateUniqueThreadId } from '../ThreadId';
import SamplePrompts from '../SamplePrompts';
import { ThreeDots } from "react-loader-spinner";
import { push } from 'react-router-redux'
import WebBannerBrowser from "../Constants/web-banner-browser.svg"

class AppIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prompt: "",
            chatHistory: [],
            fetching: false,
            showLoginModal: false,
            samplePromptClicked: false,
            samplePromptsVisible: true,
            promptCanceled: false,
            isDisplayBoxExpanded: false,
            textAreaRows: 1,
            emailInput: "",
            emailValidationError: "",
            showSignupTextarea: false,
            setChatHistory: [],
            qa_chain_pickle_file_name: null
        };
        this.textareaRef = React.createRef();
    }
    componentDidMount() {
        if (window.location.pathname === "/"){
            // this is the home page
            this.props.navigateTo("/beta")
        } else {
            fetch("https://get.geojs.io/v1/ip/geo.json")
                .then(res => res.json())
                .then(data => {
                    const ipAddress = {
                        ip_address: data.ip,
                    };
                    localStorage.setItem("ipInfo", JSON.stringify(data));
                    localStorage.setItem("ip_address", JSON.stringify(ipAddress));
                    this.sendIpToFlask(ipAddress, data); // Send the ipInfo to the Flask backend
                })
                .catch(error => {
                    console.error('Error fetching IP information:', error);
                });
            const sessionId = localStorage.getItem('sessionId');
            // Check if the user has a "sessionId" and email in localStorage
            const isUserSignedIn = localStorage.getItem('email') !== null;
            // If there is no email (logged in) check if sessionId in localStorage, generate and set one
            if (!isUserSignedIn) {
                if (!sessionId) {
                    const uniqueSessionId = generateUniqueSessionId();
                    localStorage.setItem('sessionId', uniqueSessionId);
                }
            }
            const uniqueThreadId = generateUniqueThreadId();
            localStorage.setItem('threadId', uniqueThreadId);
        }
    }

    sendIpToFlask = (ipAddress, ipInfo) => {
        const sessionId = localStorage.getItem('sessionId');
        // Send IP address to Flask "/postgres-db" endpoint
        fetch(API_Root + "postgres-db", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "ipInfo": ipInfo,
                "ip_address": ipAddress,
                "sessionId": sessionId
            }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Response from Flask:', data);
        })
        .catch(error => {
            console.error('Error sending IP to Flask:', error);
        });
    }
    handleInputChange = (event) => {
        const val = event.target.value;
        const elmName = event.target.name;

        this.setState({ [elmName]: val, emailValidationError: "" })
    };
    validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      
        // Check if the email is empty
        if (email.trim() === "") {
          return "";
        }
      
        // Check if the email is valid using regex
        if (!emailRegex.test(email)) {
          return "Invalid email address.";
        }
      
        // Email is valid
        return "";
      };
    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (event.shiftKey) {
                // If Enter is pressed with Shift, add a newline.
                event.preventDefault();
                const { selectionStart, selectionEnd } = this.textareaRef;
                const val = this.state.prompt;
    
                if (selectionStart !== null && selectionEnd !== null) {
                    const newPromptValue =
                        val.substring(0, selectionStart) +
                        '\n' +
                        val.substring(selectionEnd);
    
                    const lineCount = (newPromptValue.match(/\n/g) || []).length + 1;
    
                    this.setState({ prompt: newPromptValue, textAreaRows: Math.min(lineCount, 3) }, () => {
                        this.textareaRef.setSelectionRange(selectionStart + 2, selectionStart + 2);
                    });
                }
            } else {
                // If Enter is pressed without Shift, do nothing.
                event.preventDefault();
            }
        } else if (event.key === 'Backspace' || event.key === 'Delete') {
            // If Backspace or Delete is pressed, update the number of rows
            const val = this.state.prompt;
            const lineCount = (val.match(/\n/g) || []).length + 1;
            const updatedRows = Math.min(lineCount, 3);
    
            this.setState({ textAreaRows: updatedRows });
        }
    };
    getChatResp = (event) => {
        // Create an AbortController instance
        const abortController = new AbortController();
    
        // Signal associated with the AbortController
        const abortSignal = abortController.signal;

        this.setState({  fetching: true, samplePromptsVisible: false, samplePromptClicked: true , isDisplayBoxExpanded: true, promptCanceled: false })

        const isError = (errorMsg) => {  
            this.props.toggleToast({show: true, type: "error", message:errorMsg});
            this.setState({ fetching: false })
          }

        const userEmail = localStorage.getItem('email');
        const sessionId = localStorage.getItem('sessionId');
        const threadId = localStorage.getItem('threadId');
        
        const requestData = {
            "chat_history": this.state.chatHistory,
            "sessionId": userEmail || sessionId, // Use userEmail if signed in, otherwise sessionId
            "prompt": this.state.prompt,
            "threadId": threadId,
            "qa_chain_pickle_file_name": this.state.qa_chain_pickle_file_name
          };

        // const API_end_root = 'chatOnly' in this.props ? 'chat-no-docs' : "chat"
        let API_end_root = 'chat-no-docs'

        if ('chatNoDb' in this.props){
            API_end_root = 'chat-no-db'
        } else if ('chatFineTune' in this.props){
            API_end_root = 'chat-fine-tuned-model'
        }

        fetch(API_Root + API_end_root, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json' // Set content type to JSON
            },
            body: JSON.stringify(requestData),
            signal: abortSignal,
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            // Check if the request was not aborted before updating the state
            if (!abortController.signal.aborted) {
                this.setState({
                    chatHistory: data['chat_history'],
                    answer: data['answer'],
                    qa_chain_pickle_file_name: data['qa_chain_pickle_file_name'],
                    prompt: "",
                    fetching: false,
                    promptCanceled: false,
                    textAreaRows: 1,
                });
            }
        })
        .catch(error => {
            // Check if the request was not aborted before handling the error
            if (!abortController.signal.aborted) {
                console.error('Error in getChatResp:', error);
                isError("There was an error getting response, please try again.");
            }
        });
        this.setState({ abortController });
    };
    //function to cancel fetch
    cancelFetch = () => {
        if (this.state.abortController) {
            this.state.abortController.abort();
            this.setState({ fetching: false, promptCanceled: true });
        }
    };
    //this will handle when sample prompts are clicked, it will run the getchatresp function with the sample prompt.
    handleSamplePromptClick = (samplePrompt) => {
        this.setState({ prompt: samplePrompt, samplePromptClicked: true, samplePromptsVisible: false }, () => {
            this.getChatResp();
        });
    };

    toggleDisplayBox = () => {
        this.setState((prevState) => ({
            isDisplayBoxExpanded: !prevState.isDisplayBoxExpanded,
        }));
    };

    handleEmailSubmit = () => {
        const { emailInput } = this.state;

        // Validate the email
        const emailValidationError = this.validateEmail(emailInput);
        this.setState({ emailValidationError });

        // If there's a validation error, stop the submission
        if (emailValidationError) {
            this.props.toggleToast({show: true, type: "error", message:"Please enter a valid email address."});
            return;
        }
    
        if (emailInput.trim() === "") {
            return;
        }
    
        const ipAddress = JSON.parse(localStorage.getItem("ip_address"));
        const sessionId = localStorage.getItem('sessionId');
    
        if (!ipAddress) {
            return;
        }
        fetch(API_Root + "signup", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "email": emailInput,
                "ip_address": ipAddress,
                "sessionId": sessionId
            }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Response from /signup:', data);
            this.props.toggleToast({show: true, type: "success", message:"Sucessfully signed up!"});
        })
        .catch(error => {
            console.error('Error in /signup:', error);
        });
        setTimeout(() => {
            this.setState({
              emailSuccessMessage: "Email successfully sent!",
              emailInput: "", 
            });
        }, 1000);
    };

    openNewTab = () => {
        const newTabUrl = 'https://calendly.com/predictive-analytics-partners';
        window.open(newTabUrl, '_blank');
      };

    render() {
    // <div className="motto">Strategy with Analytics</div>
    //
    // <div className='infodesc'>
    //     <strong>Predictive Analytics Partners aligns companies business strategy with analytics to empower their talented teams</strong><br /><br />
    //     <strong>Our Approach:</strong><br />
    //     <strong>Strategy aligns operations </strong>to accomplish long term outcomes<br />
    //     <strong>Analytics are tactics </strong>to accomplish operational objectives<br />
    //     <strong>Generative AI is a Tool</strong><br />
    //     <strong>Talent is the foundation</strong>
    // </div>
        return (
            
            <div className="grid-container">
                <nav className="navigation">
                    <div className='nav-container'>
                        
                    </div>
                </nav>
                <div className="header">
                    <div className='header-container'>
                        <div className='logo'></div>
                        <div className='banner'>
                            <img src="https://predanalytics-assets.s3.amazonaws.com/web-banner-browser.svg" style={{width: '100%', height: '324px'}} />
                        </div>
                        <div className='info'>
                            <button className='contactbutton' onClick={this.openNewTab}><strong>Schedule a call with us</strong></button>
                        </div>
                        <div className='info'>
                        Please ask & have a conversation with our Generative AI bot "PATI B" below to learn more about what we do and how we can help you.
                        </div>
                        
                    </div>
                </div>
                <div className="chat">
                    <div className='display-container'>
                        <div className='display-box'>
                            <div className='chatarea-box'>
                            
                                
                                
                                {this.state.isDisplayBoxExpanded && (
                                    <div>
                                        
                                        <QuestionAnswer chatHistory={this.state.chatHistory} />
                                        {this.state.fetching && (
                                            <div className="chat-entry">
                                                
                                                <div className="prompt">
                                                    <div className='chat-flex'>
                                                        <div className='profile-box'>
                                                            <div className='profile-image'>
                                                                <img src="https://openclipart.org/image/800px/167281" alt="Profile Image" />
                                                            </div>
                                                        </div>
                                                        <div className='chat-box'>{this.state.prompt}</div>
                                                    </div>
                                                </div>
                                                <div className="answer">
                                                    <div className='chat-loading'>
                                                        <ThreeDots
                                                            color="#343a40"
                                                            height={50}
                                                            width={50}
                                                        />
                                                        <button className={'cancel-prompt-button'} onClick={() => this.cancelFetch()}>Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {this.state.promptCanceled && (
                                            <div className="chat-entry">
                                                
                                                <div className="prompt">
                                                    <div className='chat-flex'>
                                                        <div className='profile-box'>
                                                            <div className='profile-image'>
                                                                <img src="https://predanalytics-assets.s3.amazonaws.com/promptava.png" alt="Profile Image" />
                                                            </div>
                                                        </div>
                                                        <div className='chat-box'>{this.state.prompt}</div>
                                                    </div>
                                                </div>
                                                <div className="answer">
                                                    <div className='chat-flex'>
                                                        <div className='profile-box'>
                                                        <div className='profile-image'>
                                                            <img src="https://predanalytics-assets.s3.amazonaws.com/answerava.jpg" alt="Profile Image" />
                                                        </div>
                                                        </div>
                                                        <div className='chat-box'>The prompt was canceled.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className='textarea-box'>
                                <div className='input-form'>
                                    <div className='chat-icon' />
                                    <textarea
                                        ref={(ref) => (this.textareaRef = ref)}
                                        className={"input-gray"}
                                        name="prompt"
                                        placeholder="Hello, I am PATI B (Predictive Analytics Transformer Bot) ask me anything or click a question below..."
                                        value={this.state.prompt}
                                        onChange={this.handleInputChange}
                                        onKeyDown={this.handleKeyDown}
                                        rows={this.state.textAreaRows} 
                                    />
                                    <button 
                                        className={"chat-button"}
                                        disabled={this.state.prompt.length < 5}
                                        onClick={this.getChatResp} 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sample-prompts">
                    <SamplePrompts handleSamplePromptClick={this.handleSamplePromptClick} />
                </div>
                <div className='signup-container'>
                    <div className='signup-info'><strong>Sign up to receive updates</strong></div>
                    {this.state.emailValidationError && (
                        <div className='error-message'>{this.state.emailValidationError}</div>
                    )}
                    {this.state.emailSuccessMessage && (
                        <div className='success-message'>{this.state.emailSuccessMessage}</div>
                    )}
                    <div className='signup-textarea'>
                        <input
                        className='input-signup'
                        type="text"
                        placeholder="Enter your email address..."
                        value={this.state.emailInput}
                        onChange={(e) => this.setState({ emailInput: e.target.value })}
                        />
                        <button className='signup-button' onClick={this.handleEmailSubmit}>Sign Up</button>
                    </div>
                </div>
                <footer className="footer">
                    <div className='footer-container'>
                    </div>
                </footer>
            </div>
            
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleToast: toggleToast,
    navigateTo: push
}

export default connect(mapStateToProps, mapActionsToProps)(AppIndex)
import {TOGGLE_MODAL} from "../Actions/ToggleModal";
import {TOGGLE_IS_AUTH} from "../Actions/ToggleIsAuth";
import {MODIFY_MODAL_INFO} from "../Actions/ModifyModalInfo";
import {RESET_ALL_STATES} from "../Actions/ResetAllStates";
import {TOGGLE_TOAST} from "../Actions/ToggleToast";

function mainState(
    state = {
        modalName: null,
        isAuth: false,
        modalInfo: {},
        toast: {show: false, message: "", type: ""}
    }, action
) {
    switch (action.type){
        case TOGGLE_TOAST:
            return Object.assign({}, state, {
                toast: action.mainState.toast
            });
        case TOGGLE_MODAL:
            return Object.assign({}, state, {
                modalName: action.mainState.modalName
            });
        case MODIFY_MODAL_INFO:
            return Object.assign({}, state, {
                modalInfo: action.mainState.modalInfo
            });
        case TOGGLE_IS_AUTH:
            return Object.assign({}, state, {
                isAuth: action.mainState.isAuth
            });
        case RESET_ALL_STATES:
            return Object.assign({}, state, {
                modalName: null,
                isAuth: false,
                modalInfo: {},
                toast: {show: false, message: "", type: ""}
            })
        default:
            return state
    }
}

export default mainState;
import React from 'react';
import { Route, Switch } from "react-router";
import NotFound from "./SharedComponents/NotFound";
import AppIndex from "./App/AppIndex";
import Signup from "./App/Signup";
import Login from "./App/Login";
import Logout from "./App/Logout";
import AppChatOnlyIndex from "./App/AppChatOnlyIndex";
import AppFinetunedIndex from "./App/AppFinetunedIndex";

export default ({ childProps }) => {
    const isLoggedIn = !!localStorage.getItem('email');

    return (
        
        <Switch>
            <Route exact path="/" component={AppIndex} />
            <Route exact path="/beta" component={AppIndex} />
            <Route exact path="/beta-chat" component={AppChatOnlyIndex} />
            <Route exact path="/beta-finetune" component={AppFinetunedIndex} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/logout" component={Logout} />
            <Route component={NotFound}/>
        </Switch>
        
    );
};
    


/*
FIREBASE
<Route exact path="/login" render={() => (isLoggedIn ? <AppIndex /> : <Login />)} />
<Route exact path="/" render={() => (isLoggedIn ? <AppIndex /> : <Route component={Login} />)} />
1. Display loading indicator when chat is being processed
2. In Routes, create a sign-up page
3. In signup page create box for username and password
4. Use firebase to store and auth user.
5. search up libraries for react.**

In src/ create 'Authentication' folder.
In Auth folder, create auth.js,firebase.js,index.js
firebase.js -> credentials.

In Index.js

TESTING
Make another route path "testpage".
Check if auth user, if not redirect Notfound component.

DONT use firestore, use firebase authentication.

In navbar,
logout button only when user is logged in already.
*/


/* check err handling
    auth.doCreateUserWithEmailAndPassword(email, passwordOne)
            .then(authUser => {  localStorage.setItem("email", email.toLowerCase().trim() }).catch(err => console.log(err))
    getChatResp = (event) => {
        this.setState({  fetching: true })

    const isError = (errorMsg) => {  
        this.props.toggleToast({show: true, type: "error", message:errorMsg});            
        this.setState({ fetching: false })
        }
    */

/*
TODO

1.  Put log out button on navbar in the app page
2.  Create a table in a postgres database that stores the user email from the sign up page
3.  Install and set up postgres on your local computer
4.  Download a pg GUI (pgAdmin) and use that to connect to your local postgres. 
    Host: localhost, user: make_a_username, pw: make a password
5.  Create a database in there, call it test_pred_analytics, 
    and create a table in that database: users, which has a column called username
    create table users (
	id serial primary key,
	username text,
	created_dt timestamp
    )
6.  Create an API endpoint in Flask, that takes in the username as a request parameter, and saves it in the database
7.  https://pypi.org/project/psycopg2-binary/ used to connect postgres to Python. Make sure to install BINARY. Imports are the same.
8.  use this to connect to your local database, with the db parameters 
    like host: localhost, username: that_you_created, password and databasename
9.  once db is connected, you take the username from the request, and save it in the table
10. Call this API endpoint from your front end code when a user has successfully signed up

1. CSSGrid for alignment of things
2. divide up page, have different layout sections

MAIN GOALS:
1. make it look clean/nice. CHATGPT*
2. left side, conversation history button (+ new chat)
3. conversations table -> userid, conversation

4. Users first time (not signed in), assign 
Should be able to use the app without signing up at first/logging in.

TODO
convert youtube videos into pdfs (bard). If bard doesnt work as well then research atleast 3 tools to use to convert to pdf.
range free -> ...

Instead of fetching answer, install loading library or show the loading three dots.
*/ 


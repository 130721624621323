import React, {Component} from 'react'
import AppIndex from "./AppIndex"

class AppFinetunedIndex extends Component {
    render() {
        return (
        <div>
            <AppIndex
                chatFineTune
            />
        </div>
    )
    }
}

export default AppFinetunedIndex